<template>
  <v-toolbar
      color="primary"
      height="100">
    <v-container class="d-flex justify-center">
        <div class="pa-5">
          <NuxtImg
              format="webp"
              alt="Logo Sempre Internet"
              height="48"
              placeholder
              src="/logo-sempre-negativa.webp"></NuxtImg>
        </div>
    </v-container>
  </v-toolbar>
</template>
<script lang="ts">
export default defineComponent({
  name: 'HeaderSempre'
})
</script>
